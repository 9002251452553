@import "../_core/scss/variables/variables";

$scale: .5;

  .canvas {
    text-align: center;

    &__canvas {
      border: none;
      background-color: white;
      margin-bottom: 120px * $scale;
    }

    &__controls {
      display: flex;
      justify-content: center;
      gap: 100px * $scale;
    }
  }
