@import "../../scss/variables/variables";

$scale: .5;

.simple-table {
  table {
    &.table {
      table-layout: fixed;
      tbody {
        tr {
          background: none;
        }
      }
      td {
        padding: .4rem;
      }
    }
  }
}
