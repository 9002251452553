@import "../scss/variables/variables";

$scale: .5;

.apiErrorDisplay {
  background-color: rgba(0, 0, 0, 0.8);
  color: #f00;
  padding: 1rem;
  margin: 1rem;
  border-radius: 1rem;

  h2 {
    margin-top: 1rem;
  }

  code {
    color: inherit;
  }
}
