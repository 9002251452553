@import "../../scss/variables/variables";

$scale: .5;

$link-tile-icon-color: $brand--c1 !default;

.link-tile {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 30px;
  cursor: pointer;

  .icon {
    font-size: 35px;
    color: $link-tile-icon-color;
  }

  .text {
    font-size: 14px;
  }
}
