@import "../_core/scss/variables/variables";

$scale: .5;

.qrCode {
  text-align: center;

  &__imageHolder {
    margin-bottom: 70px * $scale;
    display: inline-block;
  }

  &__image {
    display: block;
    border: 30px * $scale solid var(--black);
  }

  &__copy {
    margin-bottom: 70px * $scale;
  }
}
