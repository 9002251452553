@import "../../scss/variables/variables";

$scale: .5;

.display-table {
  &__wrapper {
    display: flex;
    align-content: center;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;

    .display-table {
      &__title {

      }

      &__content {
        .display-table {
          &__row {
            flex-direction: row;
            display: flex;
            width: 100%;
            align-content: center;

            .display-table {
              &__row-title {
                width: 25%;
              }

              &__row-content {
                width: 75%;
              }
            }
          }
        }
      }
    }
  }
}
