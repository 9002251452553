@import "../../scss/variables/variables";

$scale: .5;

.toClone {
  padding: 20px;
  margin-bottom: 50px;
  background: #fff;

  .bmd-form-group {
    > .input-group {
      input {
        width: 100%
      }

      .clone-wrapper {
        width: 100%;

        .toClone {
          padding: 5px;
          margin-bottom: 10px;

          input {
            float: left;
            width: 50%;
          }
        }
      }

      > .input-group-btn {
        width: 100%;
      }
    }
  }
}
