@import '../_core/scss/style';

@import "../assets/fonts/basically-a-mono/basically-a-mono";

body {
  --yellow: #fee01b;
  --black: #000;
  --white: #fff;
  --primary: var(--yellow);
}

img {
  max-width: 100%;
  height: auto;
}

.intuit-title {
  font-weight: 700;
  font-family: 'Basically A Mono', monospace;
  font-size: 100px * $scale;
  margin-bottom: 80px * $scale;
  line-height: 110px * $scale;
}

.intuit-subtitle {
  font-weight: 700;
  font-family: 'Basically A Mono', monospace;
  font-size: 60px * $scale;
  line-height: 66px * $scale;
  margin-bottom: 60px * $scale;
}

.intuit-copy {
  font-family: helvetica, sans-serif;
  font-weight: 400;
  font-size: 50px * $scale;
  line-height: 55px * $scale;
}

.intuit-button {
  text-decoration: none;
  text-align: center;
  display: inline-block;
  height: 100px * $scale;
  min-width: 510px * $scale;
  white-space: nowrap;
  color: var(--black);
  font-weight: 700;
  font-family: 'Basically A Mono', monospace;
  font-size: 50px * $scale;
  line-height: 100px * $scale;
  border-radius: 100px * $scale;
  border-style: none;
  text-transform: uppercase;
  background-color: var(--white);
  border-color: white;

  &--primary {
    background-color: var(--primary);
    border-color: var(--primary);
  }
}
