/**
DO NOT EDIT OUTSIDE OF ICARUS.
 */

h6, h5, h4, h3, h2, h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1 {
  font-size: calc(1.375rem + 1.1vw);
  margin-bottom: 0;
}
@media (min-width: 1200px) {
  h1 {
    font-size: 1.8rem;
  }
}

h2 {
  font-size: calc(1.325rem + 0.75vw);
}
@media (min-width: 1200px) {
  h2 {
    font-size: 1.6rem;
  }
}

h3 {
  font-size: calc(1.3rem + 0.5vw);
}
@media (min-width: 1200px) {
  h3 {
    font-size: 1.45rem;
  }
}

h4 {
  font-size: calc(1.12rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4 {
    font-size: 1.275rem;
  }
}

h5 {
  font-size: 1.15rem;
}

h6 {
  font-size: 1rem;
}

body {
  font-weight: 100;
  letter-spacing: 1px;
}