@import "../_core/scss/variables/variables";

$scale: .5;

.ready {
  text-align: center;

  &__copy {
    margin: (150px * $scale) 0;
  }

}
