@import "../../scss/variables/variables";

$scale: .5;



.horizontal-nav {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  .group {
    padding-left: 0;
    padding-right: 0;
    font-size: 18px;
    font-weight: 300;

    &:hover {
      background: #eee;
    }

    a {
      padding: 15px 45px 15px 25px;
      display: block;
      text-decoration: none;
      position: relative;

      &.dropdown-toggle {
        &:after {
          display: block;
          top: 25px;
          right: 25px;
          position: absolute;
        }
      }
    }

    i {
      margin-right: 15px;
    }

    .menu-group {
      > ul {
        padding: 0;
        background: #ddd;
        z-index: 9;
        width: 200vw;
        position: absolute;
        margin-bottom: 0;
        margin-top: 9px;
        margin-left: -100vw;
        left: 100%;

        &.collapsed {
          display: none;
        }

        &.show {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
        }

        li {
          ul {
            background: #ddd;
            display: flex;
            flex-direction: row;
            padding-left: 40vw;

            li {
              list-style: none;
              padding: 10px 20px;

              &:hover {
                background: $brand--c1;
                cursor: pointer;

                a {
                  color: $brand--c1--hover-text;
                }
              }

              a {
                padding: 0;
              }
            }
          }
        }
      }
    }
  }

  #horizontal-nav {
    display: flex;
  }
}

@media(max-width: 1200px) {

  #horizontal-nav {
    flex-direction: column;

    &.collapsed {
      display: none;
    }
  }

  .horizontal-nav .group a {
    padding: 15px 30px 15px 15px;
  }

  .horizontal-nav .group a.dropdown-toggle:after {
    right: 10px;
  }
}

button.navbar-toggle {
  display: none;
}

@media(max-width: 1024px) {
  #horizontal-nav {
    display: none;

    &.show {
      display: flex !important;
      height: auto !important;
      flex-direction: column;
      justify-content: center;
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: rgba(255, 255, 255, 0.95);
      z-index: 10;
    }
  }

  .horizontal-nav .group {
    width: 90%;
    max-width: 300px;
    margin: 0 auto;
  }

  button.navbar-toggle {
    display: block;
    width: 40px;
    height: 24px;
    position: absolute;
    top: 15px;
    right: 10px;
    border: 0;
    background: transparent;
    cursor: pointer;
    z-index: 20;

    .icon-bar {
      position: absolute;
      width: 100%;
      height: 4px;
      transition-timing-function: ease;
      transition-duration: .15s;
      transition-property: transform;
      border-radius: 4px;
      background-color: #8700cd;
      left: 0;
      top: 2px;

      &:nth-child(2) {
        top: 11px;
        transition-timing-function: ease;
        transition-duration: .15s;
        transition-property: transform, opacity;
      }

      &:nth-child(3) {
        top: 20px;
      }
    }

    &:not(.collapsed) {
      .icon-bar {
        transform: translate3d(0, 9px, 0) rotate(45deg);

        &:nth-child(2) {
          transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
          opacity: 0;
        }

        &:nth-child(3) {
          transform: translate3d(0, -10px, 0) rotate(-45deg);
          opacity: 1;
        }
      }
    }
  }
}

