#pageContent {
  #pageContentInner {
    &.showing-admin-menu {
      margin-top: $admin--menu--depth !important;
    }
  }
}
.administration-menu{
  position: fixed;
  top: 0;
  z-index: 9;
  background: $admin--menu--bg--color;
  font-size: 14px;
  .horizontal-nav {
    align-items: center;
    justify-content: center;
    .group{
      &:hover{
        background: $admin--menu--bg--color--hover;
      }
      a {
        color: $admin--menu--link--color;
        font-size: 14px;
      }
      .menu-group {
        > ul{
          padding: 0;
          background: $admin--menu--bg--color--hover;
          z-index: 9;
          width: auto;
          //position: absolute;
          margin-top: 0;
          margin-left: 0;
          left: initial;
          min-width: 250px;
          li {
            width:100%;
            ul {
              background: $admin--menu--bg--color--hover;
              display: flex;
              flex-direction: column;
              padding-left: 0;
              li{
                &:hover{
                    color: lighten($admin--menu--link--color, 10%);
                }
              }
            }
          }
        }
      }
    }
  }
}