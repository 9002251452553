@import "../_core/scss/variables/variables";

$scale: .5;


.result {
  text-align: center;

  &__background {
    position: fixed;
    inset: 0;
    transition: inset 200ms linear;

    &--black {
      background-color: var(--black);
      z-index: 10;
      inset: 150px * $scale;
    }

    &--gradient {
      background-image: url('../assets/backgrounds/gradient.webp');
      background-size: cover;
      z-index: 0;
    }
  }

  &__content {
    position: relative;
    z-index: 10;
    color: var(--white);
  }

  &__logo {
    position: absolute;
    z-index: 60;
    top: 30px * $scale;
    right: 150px * $scale;
    display: flex;
    margin: 0;
    gap: 16px * $scale;

    img {
      display: block;
    }
  }

  &__image {
    margin-bottom: 70px * $scale;

    img {
      display: inline-block;
      max-height: 900px * $scale;
      max-width: 100%;
      border: 20px * $scale solid white;
    }
  }
}
