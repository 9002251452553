@import "../../scss/variables/variables";

$scale: .5;

.title-tile {
  background: $c-white;

  .breadcrumbs {
    overflow: auto;
    font-size: 0.7rem;

    ul {
      margin: 0;
      list-style: none;
      padding-left: 0;

      li {
        float: left;

        .divider {
          margin-left: 5px;
          margin-right: 5px;
        }
      }
    }
  }
}
