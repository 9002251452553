@import "../../scss/variables/variables";

$scale: .5;

.error-wrapper {
  background: #b03939;;
  color: $c-white;
  padding: 10px;
  margin-bottom: 10px;
  .text{
    width: 200px;
    display: inline-block;
  }
  .svg {
    display: inline-block;
    vertical-align: middle;
    margin-top: -4px;
    svg {
      background: transparent !important;
      width: 25px;
      height: 25px;
      fill: $c-white;
    }
  }
}
