@import "../_core/scss/variables/variables";

$scale: .5;

.draw {
  &__background {
    position: fixed;
    inset: 0;
    transition: inset 200ms linear;

    &--black {
      background-color: var(--black);
      z-index: 10;
      inset: 150px * $scale;
    }

    &--gradient {
      background-image: url('../assets/backgrounds/gradient.webp');
      background-size: cover;
      z-index: 0;
    }
  }

  &__backgroundWord {
    --translateX: 0;
    --translateY: 0;
    --rotate: 0deg;
    --scale: 1;

    position: fixed;
    z-index: 5;
    background-color: var(--yellow);
    color: var(--black);
    text-transform: uppercase;
    height: 150px * $scale;
    font-size: 150px * $scale;
    line-height: 150px * $scale;
    font-weight: bold;
    padding: 0 10px * $scale;
    transition: transform 200ms linear;
    transform: translateX(var(--translateX)) translateY(var(--translateY)) rotate(var(--rotate)) scale(var(--scale));

    &--from {
      top: 0;
      left: -220px;
      --rotate: -90deg;
      transform-origin: top right;
    }

    &--here {
      top: 0;
      left: 150px * $scale;
    }

    &--to {
      bottom: 150px * $scale;
      right: -109px;
      --rotate: -90deg;
      transform-origin: bottom left;
    }

    &--there {
      bottom: 0;
      right: 0;
      --rotate: 180deg;
    }

  }

  &__logo {
    position: fixed;
    z-index: 60;
    top: 30px * $scale;
    right: 150px * $scale;
    display: flex;
    justify-content: flex-end;
    margin: 0;
    gap: 16px * $scale;
    height: 94px * $scale;

    img {
      display: block;
    }
  }

  .logoHead {
    transition: transform 200ms linear;
  }

  .logoName {
    opacity: 1;
    transition: opacity 200ms linear;
  }

  &__content {
    color: var(--white);
    position: fixed;
    z-index: 50;
    inset: 300px * $scale;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--stage_canvas {
    .draw__content {
      inset: 150px * $scale;
    }

    .draw__background--black {
      inset: 0;
    }
  }

  &--stage_waiting {
    .draw__background--black {
      inset: 0;
      z-index: 0;
    }

    .draw__background--gradient {
      inset: 150px * $scale;
      z-index: 10;
    }

    .logoHead {
      --translateX: -285px;
      --translateY: 255px;
      --scale: 2.4;

      transform: translateX(var(--translateX)) translateY(var(--translateY)) scale(var(--scale));
    }

    .logoName {
      opacity: 0;
    }
  }

  &--stage_qr_code {
    .draw__background--black {
      inset: 100%;
    }
    .draw__backgroundWord {
      --scale: 0.3;
    }

    .draw__backgroundWord--here {
    --translateX: 221px;
    --translateY: 165px;
    }
    .draw__backgroundWord--from {
    --translateX: 343px;
    --translateY: 191px;
    }
    .draw__backgroundWord--to {
    --translateX: -342px;
    --translateY: -171px;
    }
    .draw__backgroundWord--there {
    --translateX: -255px;
    --translateY: -197px;
    }
  }
}
