@import "../_core/scss/variables/variables";

$scale: .5;

.waiting {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__spinner {
    width: 550px * $scale;
    font-size: 4.5px;
    font-weight: bold;
    font-family: "Basically A Mono", monospace;
    color: var(--white);
    animation: spin 20s linear infinite;
    margin-bottom: 70px * $scale
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
