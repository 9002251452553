button:disabled,
button[disabled="true"]{
  background: #6d6d6d;
}

.btn {
  &.btn-primary{
    color: $button--primary--text--color;
    background-color: $button--primary--color;
    border-color: $button--primary--color;
    &:hover{
      background-color: $button--primary--color--hover;
      border-color: $button--primary--color--hover;
    }
  }
}