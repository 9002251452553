@font-face {
  font-family: 'Basically A Mono';
  src: url('./assets/fonts/basically-a-mono/BasicallyAMono-ExtraLight.woff2');
  font-weight: 200;
}

@font-face {
  font-family: 'Basically A Mono';
  src: url('./assets/fonts/basically-a-mono/BasicallyAMono-Light.woff2');
  font-weight: 300;
}

@font-face {
  font-family: 'Basically A Mono';
  src: url('./assets/fonts/basically-a-mono/BasicallyAMono-Regular.woff2');
  font-weight: 400;
}

@font-face {
  font-family: 'Basically A Mono';
  src: url('./assets/fonts/basically-a-mono/BasicallyAMono-Medium.woff2');
  font-weight: 500;
}

@font-face {
  font-family: 'Basically A Mono';
  src: url('./assets/fonts/basically-a-mono/BasicallyAMono-SemiBold.woff2');
  font-weight: 600;
}

@font-face {
  font-family: 'Basically A Mono';
  src: url('./assets/fonts/basically-a-mono/BasicallyAMono-Bold.woff2');
  font-weight: 700;
}
